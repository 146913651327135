// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.content .item {
  display: flex;
  justify-content: space-between;
  font-size: var(--text-size-18);
  font-weight: 500;
}
.content .item .name {
  padding-top: 2px;
  opacity: 0.6;
  font-size: var(--text-size-15);
}
.content .item .value {
  letter-spacing: -0.01em;
  text-align: end;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhci1kYXRhLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLFNBQUE7RUFDQSxzQkFBQTtBQUNGO0FBQ0U7RUFDRSxhQUFBO0VBQ0EsOEJBQUE7RUFDQSw4QkFBQTtFQUNBLGdCQUFBO0FBQ0o7QUFDSTtFQUNFLGdCQUFBO0VBQ0EsWUFBQTtFQUNBLDhCQUFBO0FBQ047QUFFSTtFQUNFLHVCQUFBO0VBQ0EsZUFBQTtBQUFOIiwiZmlsZSI6ImNhci1kYXRhLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNvbnRlbnQge1xuICBkaXNwbGF5OiBmbGV4O1xuICBnYXA6IDIwcHg7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG5cbiAgLml0ZW0ge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuICAgIGZvbnQtc2l6ZTogdmFyKC0tdGV4dC1zaXplLTE4KTtcbiAgICBmb250LXdlaWdodDogNTAwO1xuXG4gICAgLm5hbWUge1xuICAgICAgcGFkZGluZy10b3A6IDJweDtcbiAgICAgIG9wYWNpdHk6IDAuNjtcbiAgICAgIGZvbnQtc2l6ZTogdmFyKC0tdGV4dC1zaXplLTE1KTtcbiAgICB9XG5cbiAgICAudmFsdWUge1xuICAgICAgbGV0dGVyLXNwYWNpbmc6IC0wLjAxZW07XG4gICAgICB0ZXh0LWFsaWduOiBlbmQ7XG4gICAgfVxuICB9XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/components/car-data/car-data.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,sBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,8BAAA;EACA,gBAAA;AACJ;AACI;EACE,gBAAA;EACA,YAAA;EACA,8BAAA;AACN;AAEI;EACE,uBAAA;EACA,eAAA;AAAN;AACA,48BAA48B","sourcesContent":[".content {\n  display: flex;\n  gap: 20px;\n  flex-direction: column;\n\n  .item {\n    display: flex;\n    justify-content: space-between;\n    font-size: var(--text-size-18);\n    font-weight: 500;\n\n    .name {\n      padding-top: 2px;\n      opacity: 0.6;\n      font-size: var(--text-size-15);\n    }\n\n    .value {\n      letter-spacing: -0.01em;\n      text-align: end;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
