// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  display: flex;
  max-width: max-content;
}
.content ion-button {
  --border-radius: 32px;
  --padding-end: 0;
  --padding-start: 0;
  display: flex !important;
  align-items: center;
  width: 50px;
  height: 40px;
}
.content ion-button::part(native) {
  --padding-end: 0;
  --padding-start: 0;
  --padding-bottom: 0;
  --padding-top: 0;
  width: 50px;
  height: 40px;
  min-height: 40px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJhY2stYnV0dG9uLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLHNCQUFBO0FBQ0Y7QUFDRTtFQUNFLHFCQUFBO0VBQ0EsZ0JBQUE7RUFDQSxrQkFBQTtFQUVBLHdCQUFBO0VBQ0EsbUJBQUE7RUFDQSxXQUFBO0VBQ0EsWUFBQTtBQUFKO0FBRUk7RUFDRSxnQkFBQTtFQUNBLGtCQUFBO0VBQ0EsbUJBQUE7RUFDQSxnQkFBQTtFQUVBLFdBQUE7RUFDQSxZQUFBO0VBQ0EsZ0JBQUE7QUFETiIsImZpbGUiOiJiYWNrLWJ1dHRvbi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jb250ZW50IHtcbiAgZGlzcGxheTogZmxleDtcbiAgbWF4LXdpZHRoOiBtYXgtY29udGVudDtcblxuICBpb24tYnV0dG9uIHtcbiAgICAtLWJvcmRlci1yYWRpdXM6IDMycHg7XG4gICAgLS1wYWRkaW5nLWVuZDogMDtcbiAgICAtLXBhZGRpbmctc3RhcnQ6IDA7XG5cbiAgICBkaXNwbGF5OiBmbGV4ICFpbXBvcnRhbnQ7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICB3aWR0aDogNTBweDtcbiAgICBoZWlnaHQ6IDQwcHg7XG5cbiAgICAmOjpwYXJ0KG5hdGl2ZSkge1xuICAgICAgLS1wYWRkaW5nLWVuZDogMDtcbiAgICAgIC0tcGFkZGluZy1zdGFydDogMDtcbiAgICAgIC0tcGFkZGluZy1ib3R0b206IDA7XG4gICAgICAtLXBhZGRpbmctdG9wOiAwO1xuXG4gICAgICB3aWR0aDogNTBweDtcbiAgICAgIGhlaWdodDogNDBweDtcbiAgICAgIG1pbi1oZWlnaHQ6IDQwcHg7XG4gICAgfVxuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/back-button/back-button.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,qBAAA;EACA,gBAAA;EACA,kBAAA;EAEA,wBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AAAJ;AAEI;EACE,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EAEA,WAAA;EACA,YAAA;EACA,gBAAA;AADN;AACA,4lCAA4lC","sourcesContent":[".content {\n  display: flex;\n  max-width: max-content;\n\n  ion-button {\n    --border-radius: 32px;\n    --padding-end: 0;\n    --padding-start: 0;\n\n    display: flex !important;\n    align-items: center;\n    width: 50px;\n    height: 40px;\n\n    &::part(native) {\n      --padding-end: 0;\n      --padding-start: 0;\n      --padding-bottom: 0;\n      --padding-top: 0;\n\n      width: 50px;\n      height: 40px;\n      min-height: 40px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
